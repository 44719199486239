import { render, staticRenderFns } from "./FormData.vue?vue&type=template&id=5a9c1fda&scoped=true&"
import script from "./FormData.vue?vue&type=script&lang=js&"
export * from "./FormData.vue?vue&type=script&lang=js&"
import style0 from "./FormData.vue?vue&type=style&index=0&id=5a9c1fda&prod&lang=scss&"
import style1 from "./FormData.vue?vue&type=style&index=1&id=5a9c1fda&prod&lang=scss&scoped=true&"
import style2 from "./FormData.vue?vue&type=style&index=2&id=5a9c1fda&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9c1fda",
  null
  
)

export default component.exports